/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ClickReportPayload = {
  events: Array<InputMaybe<Scalars['String']['input']>>;
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type ClickReportResponse = {
  __typename?: 'ClickReportResponse';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ClickReportUserPayload = {
  events: Array<InputMaybe<Scalars['String']['input']>>;
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type ClickReportUserResponse = {
  __typename?: 'ClickReportUserResponse';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CreatePlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTenantInput = {
  allowedUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TenantStatuses>;
  webhook?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTenantUserInvitationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<TenantUserRoles>;
  tenant: TenantInput;
};

export type CreateUserInvitationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoles>;
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NoApply = 'NO_APPLY'
}

export type HeatMapDateTime = {
  __typename?: 'HeatMapDateTime';
  date?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
};

export type HeatMapPayload = {
  event?: InputMaybe<Scalars['String']['input']>;
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type HeatMapResponse = {
  __typename?: 'HeatMapResponse';
  count?: Maybe<Scalars['Int']['output']>;
  date_time?: Maybe<HeatMapDateTime>;
};

export type MetricGroupPayload = {
  __typename?: 'MetricGroupPayload';
  list?: Maybe<Array<Maybe<User>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword?: Maybe<AuthPayload>;
  createPlan?: Maybe<Plan>;
  createTenant?: Maybe<Tenant>;
  createTenantUserInvitation?: Maybe<TenantUserInvitation>;
  createUserInvitation?: Maybe<UserInvitation>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  login?: Maybe<AuthPayload>;
  removeTenantUserInvitation?: Maybe<RemoveTenantUserInvitationPayload>;
  resendTenantUserInvitation?: Maybe<TenantUserInvitation>;
  signup?: Maybe<AuthPayload>;
  updatePlan?: Maybe<Plan>;
  updateTenant?: Maybe<Tenant>;
  updateTenantUser?: Maybe<TenantUser>;
  updateTenantUserInvitation?: Maybe<TenantUserInvitation>;
  updateUser?: Maybe<User>;
};


export type MutationChangePasswordArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


export type MutationCreateTenantUserInvitationArgs = {
  input?: InputMaybe<CreateTenantUserInvitationInput>;
};


export type MutationCreateUserInvitationArgs = {
  input?: InputMaybe<CreateUserInvitationInput>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
  form: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRemoveTenantUserInvitationArgs = {
  input?: InputMaybe<RemoveTenantUserInvitationInput>;
};


export type MutationResendTenantUserInvitationArgs = {
  input?: InputMaybe<ResendTenantUserInvitationInput>;
};


export type MutationSignupArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};


export type MutationUpdateTenantUserArgs = {
  input?: InputMaybe<UpdateTenantUserInput>;
};


export type MutationUpdateTenantUserInvitationArgs = {
  input?: InputMaybe<UpdateTenantUserInvitationInput>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Plan = {
  __typename?: 'Plan';
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PlanStatuses>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum PlanStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PlanWhereInput = {
  status?: InputMaybe<PlanStatuses>;
};

export type PlansPayload = {
  __typename?: 'PlansPayload';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<Plan>>>;
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  getClickedReport?: Maybe<Array<Maybe<ClickReportResponse>>>;
  getClickedReportUser?: Maybe<Array<Maybe<ClickReportUserResponse>>>;
  getHeatMapReport?: Maybe<Array<Maybe<HeatMapResponse>>>;
  getRegisteredUserReport?: Maybe<RegisteredUserReportResponse>;
  getSwgTap?: Maybe<SwgTapResponse>;
  getUrlVisitReport?: Maybe<UrlVisitResponse>;
  getUsersByMonthReport?: Maybe<Array<Maybe<UserByMonthResponse>>>;
  metricsGroup?: Maybe<User>;
  plans?: Maybe<PlansPayload>;
  sectionReport?: Maybe<Array<Maybe<SectionReportResponse>>>;
  swgTapByMonthReport?: Maybe<Array<Maybe<SwgTapByMonthResponse>>>;
  swgTapBySectionReport?: Maybe<Array<Maybe<SwgTapBySectionReportResponse>>>;
  swgTapByUrlReport?: Maybe<SwgTapByUrlResponse>;
  swgTapByUrlReportMetric?: Maybe<SwgTapByUrlMetricResponse>;
  tenant?: Maybe<Tenant>;
  tenantUserInvitations?: Maybe<TenantUserInvitationsPayload>;
  tenantUsers?: Maybe<TenantUsersPayload>;
  tenants?: Maybe<TenantsPayload>;
  userEvents?: Maybe<UserEventResponse>;
  userInvitations?: Maybe<UserInvitationsResponse>;
  userSession?: Maybe<Array<Maybe<UserSessionResponse>>>;
  visitPageByUsers?: Maybe<VisitPageByUserResponse>;
  winnerNotes?: Maybe<Array<Maybe<WinnerNotesResponse>>>;
};


export type QueryGetClickedReportArgs = {
  where?: InputMaybe<ClickReportPayload>;
};


export type QueryGetClickedReportUserArgs = {
  where?: InputMaybe<ClickReportUserPayload>;
};


export type QueryGetHeatMapReportArgs = {
  where?: InputMaybe<HeatMapPayload>;
};


export type QueryGetRegisteredUserReportArgs = {
  where?: InputMaybe<RegisteredUserReportPayload>;
};


export type QueryGetSwgTapArgs = {
  where?: InputMaybe<SwgTapPayload>;
};


export type QueryGetUrlVisitReportArgs = {
  where?: InputMaybe<UrlVisitPayload>;
};


export type QueryGetUsersByMonthReportArgs = {
  where?: InputMaybe<UserByMonthPayload>;
};


export type QueryMetricsGroupArgs = {
  eventList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryPlansArgs = {
  where?: InputMaybe<PlanWhereInput>;
};


export type QuerySectionReportArgs = {
  where?: InputMaybe<SectionReportPayload>;
};


export type QuerySwgTapByMonthReportArgs = {
  where?: InputMaybe<SwgTapByMonthPayload>;
};


export type QuerySwgTapBySectionReportArgs = {
  where?: InputMaybe<SwgTapBySectionReportPayload>;
};


export type QuerySwgTapByUrlReportArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SwgTapByUrlPayload>;
};


export type QuerySwgTapByUrlReportMetricArgs = {
  where?: InputMaybe<SwgTapByUrlMetricPayload>;
};


export type QueryTenantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTenantUserInvitationsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TenantUserInvitationWhereInput>;
};


export type QueryTenantUsersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TenantUserWhereInput>;
};


export type QueryUserEventsArgs = {
  tenant_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserSessionArgs = {
  where?: InputMaybe<UserSessionWhere>;
};


export type QueryVisitPageByUsersArgs = {
  where?: InputMaybe<VisitPageByUsersPayload>;
};


export type QueryWinnerNotesArgs = {
  order?: InputMaybe<WinnerNoteOrder>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WinnerNotesWhere>;
};

export type RegisteredUserReportPayload = {
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type RegisteredUserReportResponse = {
  __typename?: 'RegisteredUserReportResponse';
  total_user_anonymous?: Maybe<Scalars['Int']['output']>;
  total_users_registered?: Maybe<Scalars['Int']['output']>;
};

export type RemoveTenantUserInvitationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveTenantUserInvitationPayload = {
  __typename?: 'RemoveTenantUserInvitationPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ResendTenantUserInvitationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type SectionReportPayload = {
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type SectionReportResponse = {
  __typename?: 'SectionReportResponse';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortTableInput = {
  field?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<SortOrder>;
};

export type SwgTapByMonthPayload = {
  from: Scalars['String']['input'];
  period?: InputMaybe<Scalars['String']['input']>;
  tenantId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};

export type SwgTapByMonthResponse = {
  __typename?: 'SwgTapByMonthResponse';
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
};

export type SwgTapBySectionReportPayload = {
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type SwgTapBySectionReportResponse = {
  __typename?: 'SwgTapBySectionReportResponse';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SwgTapByUrlDataMetricResponse = {
  __typename?: 'SwgTapByUrlDataMetricResponse';
  count?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SwgTapByUrlDataResponse = {
  __typename?: 'SwgTapByUrlDataResponse';
  count?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SwgTapByUrlMetricPayload = {
  from: Scalars['String']['input'];
  section?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type SwgTapByUrlMetricResponse = {
  __typename?: 'SwgTapByUrlMetricResponse';
  data?: Maybe<Array<Maybe<SwgTapByUrlDataMetricResponse>>>;
};

export enum SwgTapByUrlOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SwgTapByUrlPayload = {
  from: Scalars['String']['input'];
  order?: InputMaybe<SwgTapByUrlOrder>;
  section?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type SwgTapByUrlResponse = {
  __typename?: 'SwgTapByUrlResponse';
  data?: Maybe<Array<Maybe<SwgTapByUrlDataResponse>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SwgTapPayload = {
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type SwgTapResponse = {
  __typename?: 'SwgTapResponse';
  total_swg_in_home?: Maybe<Scalars['Int']['output']>;
  total_swg_in_other_section?: Maybe<Scalars['Int']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  allowedUrls?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identityColor?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  status?: Maybe<TenantStatuses>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  webhook?: Maybe<Scalars['String']['output']>;
};

export type TenantInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum TenantStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type TenantUser = {
  __typename?: 'TenantUser';
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  role?: Maybe<TenantUserRoles>;
  status?: Maybe<TenantUserStatuses>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type TenantUserInvitation = {
  __typename?: 'TenantUserInvitation';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<TenantUserRoles>;
  status?: Maybe<TenantUserInvitationStatuses>;
  tenant?: Maybe<Tenant>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum TenantUserInvitationStatuses {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type TenantUserInvitationWhereInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<TenantUserRoles>;
  sort?: InputMaybe<SortTableInput>;
  status?: InputMaybe<TenantUserInvitationStatuses>;
  tenant?: InputMaybe<TenantInput>;
};

export type TenantUserInvitationsPayload = {
  __typename?: 'TenantUserInvitationsPayload';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<TenantUserInvitation>>>;
};

export enum TenantUserRoles {
  TenantAdministrator = 'TENANT_ADMINISTRATOR',
  TenantUser = 'TENANT_USER'
}

export enum TenantUserStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type TenantUserUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TenantUserWhereInput = {
  role?: InputMaybe<TenantUserRoles>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortTableInput>;
  tenant?: InputMaybe<TenantInput>;
};

export type TenantUsersPayload = {
  __typename?: 'TenantUsersPayload';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<TenantUser>>>;
};

export type TenantsPayload = {
  __typename?: 'TenantsPayload';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<Tenant>>>;
};

export type UpdatePlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PlanStatuses>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantInput = {
  allowedUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  identityColor?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<UpdateTenantPlanInput>;
  status?: InputMaybe<TenantStatuses>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  webhook?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantPlanInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTenantUserInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<TenantUserRoles>;
  status?: InputMaybe<TenantUserStatuses>;
  user?: InputMaybe<TenantUserUserInput>;
};

export type UpdateTenantUserInvitationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TenantUserInvitationStatuses>;
};

export type UpdateUserInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UrlVisitDataResponse = {
  __typename?: 'UrlVisitDataResponse';
  count?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UrlVisitPayload = {
  from: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type UrlVisitResponse = {
  __typename?: 'UrlVisitResponse';
  data?: Maybe<Array<Maybe<UrlVisitDataResponse>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_login?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  tenantUsers?: Maybe<Array<Maybe<TenantUser>>>;
  timezone?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type UserByMonthPayload = {
  from: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};

export type UserByMonthResponse = {
  __typename?: 'UserByMonthResponse';
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
};

export type UserEvent = {
  __typename?: 'UserEvent';
  created_at?: Maybe<Scalars['String']['output']>;
  event_meta?: Maybe<Array<Maybe<UserEventMeta>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type UserEventMeta = {
  __typename?: 'UserEventMeta';
  id?: Maybe<Scalars['String']['output']>;
  meta_key?: Maybe<Scalars['String']['output']>;
  meta_value?: Maybe<Scalars['String']['output']>;
};

export type UserEventResponse = {
  __typename?: 'UserEventResponse';
  count?: Maybe<Scalars['Int']['output']>;
  events?: Maybe<Array<Maybe<UserEvent>>>;
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UserRoles>;
  status?: Maybe<UserInvitationStatuses>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum UserInvitationStatuses {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type UserInvitationsResponse = {
  __typename?: 'UserInvitationsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<UserInvitation>>>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<UserRoles>;
};

export enum UserRoles {
  Administrator = 'ADMINISTRATOR',
  User = 'USER'
}

export type UserSessionResponse = {
  __typename?: 'UserSessionResponse';
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
};

export type UserSessionWhere = {
  from?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<Scalars['Boolean']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type VisitPageByUserResponse = {
  __typename?: 'VisitPageByUserResponse';
  total_user_anonymous?: Maybe<Scalars['Int']['output']>;
  total_users_registered?: Maybe<Scalars['Int']['output']>;
};

export type VisitPageByUsersPayload = {
  from: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};

export type WinnerNote = {
  __typename?: 'WinnerNote';
  count?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum WinnerNoteOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type WinnerNotesResponse = {
  __typename?: 'WinnerNotesResponse';
  items?: Maybe<Array<Maybe<WinnerNote>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type WinnerNotesWhere = {
  from?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: string | null } | null } | null };

export type SignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: string | null } | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
  form: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordPayload', success: boolean | null } | null };

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: string | null } | null } | null };

export type GetClickedReportQueryVariables = Exact<{
  where?: InputMaybe<ClickReportPayload>;
}>;


export type GetClickedReportQuery = { __typename?: 'Query', getClickedReport: Array<{ __typename?: 'ClickReportResponse', name: string | null, count: number | null } | null> | null };

export type GetHeatMapReportQueryVariables = Exact<{
  where?: InputMaybe<HeatMapPayload>;
}>;


export type GetHeatMapReportQuery = { __typename?: 'Query', getHeatMapReport: Array<{ __typename?: 'HeatMapResponse', count: number | null, date_time: { __typename?: 'HeatMapDateTime', date: string | null, time: string | null } | null } | null> | null };

export type GetRegisteredUserReportQueryVariables = Exact<{
  where?: InputMaybe<RegisteredUserReportPayload>;
}>;


export type GetRegisteredUserReportQuery = { __typename?: 'Query', getRegisteredUserReport: { __typename?: 'RegisteredUserReportResponse', total_users_registered: number | null, total_user_anonymous: number | null } | null };

export type VisitPageByUsersQueryVariables = Exact<{
  where?: InputMaybe<VisitPageByUsersPayload>;
}>;


export type VisitPageByUsersQuery = { __typename?: 'Query', visitPageByUsers: { __typename?: 'VisitPageByUserResponse', total_users_registered: number | null, total_user_anonymous: number | null } | null };

export type GetUrlVisitReportQueryVariables = Exact<{
  where?: InputMaybe<UrlVisitPayload>;
}>;


export type GetUrlVisitReportQuery = { __typename?: 'Query', getUrlVisitReport: { __typename?: 'UrlVisitResponse', total: number | null, data: Array<{ __typename?: 'UrlVisitDataResponse', url: string | null, count: number | null } | null> | null } | null };

export type SectionReportQueryVariables = Exact<{
  where?: InputMaybe<SectionReportPayload>;
}>;


export type SectionReportQuery = { __typename?: 'Query', sectionReport: Array<{ __typename?: 'SectionReportResponse', name: string | null, count: number | null } | null> | null };

export type GetSwgTapQueryVariables = Exact<{
  where?: InputMaybe<SwgTapPayload>;
}>;


export type GetSwgTapQuery = { __typename?: 'Query', getSwgTap: { __typename?: 'SwgTapResponse', total_swg_in_home: number | null, total_swg_in_other_section: number | null } | null };

export type SwgTapBySectionReportQueryVariables = Exact<{
  where?: InputMaybe<SwgTapBySectionReportPayload>;
}>;


export type SwgTapBySectionReportQuery = { __typename?: 'Query', swgTapBySectionReport: Array<{ __typename?: 'SwgTapBySectionReportResponse', name: string | null, count: number | null } | null> | null };

export type SwgTapByMonthReportQueryVariables = Exact<{
  where?: InputMaybe<SwgTapByMonthPayload>;
}>;


export type SwgTapByMonthReportQuery = { __typename?: 'Query', swgTapByMonthReport: Array<{ __typename?: 'SwgTapByMonthResponse', date: string | null, count: number | null } | null> | null };

export type SwgTapByUrlReportQueryVariables = Exact<{
  where?: InputMaybe<SwgTapByUrlPayload>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SwgTapByUrlReportQuery = { __typename?: 'Query', swgTapByUrlReport: { __typename?: 'SwgTapByUrlResponse', total: number | null, data: Array<{ __typename?: 'SwgTapByUrlDataResponse', url: string | null, count: number | null } | null> | null } | null };

export type SwgTapByUrlReportMetricQueryVariables = Exact<{
  where?: InputMaybe<SwgTapByUrlMetricPayload>;
}>;


export type SwgTapByUrlReportMetricQuery = { __typename?: 'Query', swgTapByUrlReportMetric: { __typename?: 'SwgTapByUrlMetricResponse', data: Array<{ __typename?: 'SwgTapByUrlDataMetricResponse', url: string | null, count: number | null } | null> | null } | null };

export type GetClickedReportUserQueryVariables = Exact<{
  where?: InputMaybe<ClickReportUserPayload>;
}>;


export type GetClickedReportUserQuery = { __typename?: 'Query', getClickedReportUser: Array<{ __typename: 'ClickReportUserResponse', name: string | null, count: number | null } | null> | null };

export type UserSessionQueryVariables = Exact<{
  where?: InputMaybe<UserSessionWhere>;
}>;


export type UserSessionQuery = { __typename?: 'Query', userSession: Array<{ __typename?: 'UserSessionResponse', count: number | null, date: string | null } | null> | null };

export type WinnerNotesQueryVariables = Exact<{
  where?: InputMaybe<WinnerNotesWhere>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<WinnerNoteOrder>;
}>;


export type WinnerNotesQuery = { __typename?: 'Query', winnerNotes: Array<{ __typename?: 'WinnerNotesResponse', total: number | null, items: Array<{ __typename?: 'WinnerNote', count: number | null, url: string | null } | null> | null } | null> | null };

export type PlansQueryVariables = Exact<{
  where?: InputMaybe<PlanWhereInput>;
}>;


export type PlansQuery = { __typename?: 'Query', plans: { __typename?: 'PlansPayload', count: number | null, items: Array<{ __typename?: 'Plan', id: string | null, title: string | null, description: string | null, status: PlanStatuses | null, createdAt: string | null, updatedAt: string | null } | null> | null } | null };

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput;
}>;


export type CreatePlanMutation = { __typename?: 'Mutation', createPlan: { __typename?: 'Plan', createdAt: string | null, description: string | null, id: string | null, title: string | null, updatedAt: string | null } | null };

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;


export type UpdatePlanMutation = { __typename?: 'Mutation', updatePlan: { __typename?: 'Plan', id: string | null } | null };

export type TenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantsQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantsPayload', count: number | null, items: Array<{ __typename?: 'Tenant', allowedUrls: Array<string | null> | null, createdAt: string | null, id: string | null, logo: string | null, name: string | null, identityColor: string | null, status: TenantStatuses | null, timezone: string | null, updatedAt: string | null, webhook: string | null, plan: { __typename?: 'Plan', createdAt: string | null, description: string | null, id: string | null, title: string | null, updatedAt: string | null } | null } | null> | null } | null };

export type CreateTenantMutationVariables = Exact<{
  input: CreateTenantInput;
}>;


export type CreateTenantMutation = { __typename?: 'Mutation', createTenant: { __typename?: 'Tenant', allowedUrls: Array<string | null> | null, createdAt: string | null, id: string | null, logo: string | null, name: string | null, identityColor: string | null, status: TenantStatuses | null, timezone: string | null, updatedAt: string | null, webhook: string | null, plan: { __typename?: 'Plan', createdAt: string | null, description: string | null, id: string | null, title: string | null, updatedAt: string | null } | null } | null };

export type TenantQueryVariables = Exact<{
  tenantId: Scalars['ID']['input'];
}>;


export type TenantQuery = { __typename?: 'Query', tenant: { __typename?: 'Tenant', allowedUrls: Array<string | null> | null, createdAt: string | null, id: string | null, logo: string | null, name: string | null, identityColor: string | null, status: TenantStatuses | null, timezone: string | null, updatedAt: string | null, webhook: string | null, plan: { __typename?: 'Plan', createdAt: string | null, description: string | null, id: string | null, title: string | null, updatedAt: string | null } | null } | null };

export type UpdateTenantMutationVariables = Exact<{
  input: UpdateTenantInput;
}>;


export type UpdateTenantMutation = { __typename?: 'Mutation', updateTenant: { __typename?: 'Tenant', allowedUrls: Array<string | null> | null, createdAt: string | null, id: string | null, logo: string | null, name: string | null, identityColor: string | null, status: TenantStatuses | null, timezone: string | null, updatedAt: string | null, webhook: string | null, plan: { __typename?: 'Plan', createdAt: string | null, description: string | null, id: string | null, title: string | null, updatedAt: string | null } | null } | null };

export type TenantUsersQueryVariables = Exact<{
  where?: InputMaybe<TenantUserWhereInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TenantUsersQuery = { __typename?: 'Query', tenantUsers: { __typename?: 'TenantUsersPayload', count: number | null, items: Array<{ __typename?: 'TenantUser', id: string | null, createdAt: string | null, role: TenantUserRoles | null, status: TenantUserStatuses | null, tenant: { __typename?: 'Tenant', name: string | null, id: string | null, identityColor: string | null, logo: string | null, status: TenantStatuses | null } | null, user: { __typename?: 'User', name: string | null, avatar: string | null, email: string | null, id: string | null, role: string | null, last_login: string | null } | null } | null> | null } | null };

export type TenantUserInvitationsQueryVariables = Exact<{
  where?: InputMaybe<TenantUserInvitationWhereInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TenantUserInvitationsQuery = { __typename?: 'Query', tenantUserInvitations: { __typename?: 'TenantUserInvitationsPayload', count: number | null, items: Array<{ __typename?: 'TenantUserInvitation', id: string | null, role: TenantUserRoles | null, status: TenantUserInvitationStatuses | null, email: string | null, createdAt: string | null, updatedAt: string | null, tenant: { __typename?: 'Tenant', name: string | null } | null } | null> | null } | null };

export type CreateTenantUserInvitationMutationVariables = Exact<{
  input?: InputMaybe<CreateTenantUserInvitationInput>;
}>;


export type CreateTenantUserInvitationMutation = { __typename?: 'Mutation', createTenantUserInvitation: { __typename?: 'TenantUserInvitation', id: string | null } | null };

export type UpdateTenantUserInvitationMutationVariables = Exact<{
  input?: InputMaybe<UpdateTenantUserInvitationInput>;
}>;


export type UpdateTenantUserInvitationMutation = { __typename?: 'Mutation', updateTenantUserInvitation: { __typename?: 'TenantUserInvitation', id: string | null, status: TenantUserInvitationStatuses | null } | null };

export type RemoveTenantUserInvitationMutationVariables = Exact<{
  input?: InputMaybe<RemoveTenantUserInvitationInput>;
}>;


export type RemoveTenantUserInvitationMutation = { __typename?: 'Mutation', removeTenantUserInvitation: { __typename?: 'RemoveTenantUserInvitationPayload', success: boolean | null } | null };

export type ResendTenantUserInvitationMutationVariables = Exact<{
  input?: InputMaybe<ResendTenantUserInvitationInput>;
}>;


export type ResendTenantUserInvitationMutation = { __typename?: 'Mutation', resendTenantUserInvitation: { __typename?: 'TenantUserInvitation', id: string | null, role: TenantUserRoles | null, email: string | null, status: TenantUserInvitationStatuses | null, createdAt: string | null, updatedAt: string | null } | null };

export type UpdateTenantUserMutationVariables = Exact<{
  input?: InputMaybe<UpdateTenantUserInput>;
}>;


export type UpdateTenantUserMutation = { __typename?: 'Mutation', updateTenantUser: { __typename?: 'TenantUser', id: string | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string | null, name: string | null, email: string | null, last_login: string | null, updated_at: string | null, created_at: string | null, avatar: string | null, role: string | null, timezone: string | null, tenantUsers: Array<{ __typename?: 'TenantUser', id: string | null, role: TenantUserRoles | null, tenant: { __typename?: 'Tenant', id: string | null, name: string | null, timezone: string | null, webhook: string | null, logo: string | null, identityColor: string | null, plan: { __typename?: 'Plan', createdAt: string | null, description: string | null, id: string | null, title: string | null, updatedAt: string | null } | null } | null } | null> | null } | null };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string | null } | null };

export type CreateUserInvitationMutationVariables = Exact<{
  input?: InputMaybe<CreateUserInvitationInput>;
}>;


export type CreateUserInvitationMutation = { __typename?: 'Mutation', createUserInvitation: { __typename?: 'UserInvitation', id: string | null } | null };

export type UserInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInvitationsQuery = { __typename?: 'Query', userInvitations: { __typename?: 'UserInvitationsResponse', count: number | null, items: Array<{ __typename?: 'UserInvitation', id: string | null, role: UserRoles | null, email: string | null, status: UserInvitationStatuses | null, createdAt: string | null } | null> | null } | null };

export type UserEventsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UserEventsQuery = { __typename?: 'Query', userEvents: { __typename?: 'UserEventResponse', count: number | null, events: Array<{ __typename?: 'UserEvent', id: string | null, created_at: string | null, name: string | null, user_id: number | null, event_meta: Array<{ __typename?: 'UserEventMeta', id: string | null, meta_key: string | null, meta_value: string | null } | null> | null } | null> | null } | null };


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($email: String!, $password: String!, $name: String!) {
  signup(email: $email, password: $password, name: $name) {
    token
    user {
      id
    }
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!, $redirectUrl: String!, $form: String!) {
  forgotPassword(email: $email, redirectUrl: $redirectUrl, form: $form) {
    success
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($email: String!, $password: String!, $token: String!) {
  changePassword(email: $email, password: $password, token: $token) {
    user {
      id
    }
    token
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const GetClickedReportDocument = gql`
    query GetClickedReport($where: ClickReportPayload) {
  getClickedReport(where: $where) {
    name
    count
  }
}
    `;

/**
 * __useGetClickedReportQuery__
 *
 * To run a query within a React component, call `useGetClickedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClickedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClickedReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClickedReportQuery(baseOptions?: Apollo.QueryHookOptions<GetClickedReportQuery, GetClickedReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClickedReportQuery, GetClickedReportQueryVariables>(GetClickedReportDocument, options);
      }
export function useGetClickedReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClickedReportQuery, GetClickedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClickedReportQuery, GetClickedReportQueryVariables>(GetClickedReportDocument, options);
        }
export function useGetClickedReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClickedReportQuery, GetClickedReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClickedReportQuery, GetClickedReportQueryVariables>(GetClickedReportDocument, options);
        }
export type GetClickedReportQueryHookResult = ReturnType<typeof useGetClickedReportQuery>;
export type GetClickedReportLazyQueryHookResult = ReturnType<typeof useGetClickedReportLazyQuery>;
export type GetClickedReportSuspenseQueryHookResult = ReturnType<typeof useGetClickedReportSuspenseQuery>;
export type GetClickedReportQueryResult = Apollo.QueryResult<GetClickedReportQuery, GetClickedReportQueryVariables>;
export const GetHeatMapReportDocument = gql`
    query GetHeatMapReport($where: HeatMapPayload) {
  getHeatMapReport(where: $where) {
    date_time {
      date
      time
    }
    count
  }
}
    `;

/**
 * __useGetHeatMapReportQuery__
 *
 * To run a query within a React component, call `useGetHeatMapReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeatMapReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeatMapReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetHeatMapReportQuery(baseOptions?: Apollo.QueryHookOptions<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>(GetHeatMapReportDocument, options);
      }
export function useGetHeatMapReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>(GetHeatMapReportDocument, options);
        }
export function useGetHeatMapReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>(GetHeatMapReportDocument, options);
        }
export type GetHeatMapReportQueryHookResult = ReturnType<typeof useGetHeatMapReportQuery>;
export type GetHeatMapReportLazyQueryHookResult = ReturnType<typeof useGetHeatMapReportLazyQuery>;
export type GetHeatMapReportSuspenseQueryHookResult = ReturnType<typeof useGetHeatMapReportSuspenseQuery>;
export type GetHeatMapReportQueryResult = Apollo.QueryResult<GetHeatMapReportQuery, GetHeatMapReportQueryVariables>;
export const GetRegisteredUserReportDocument = gql`
    query GetRegisteredUserReport($where: RegisteredUserReportPayload) {
  getRegisteredUserReport(where: $where) {
    total_users_registered
    total_user_anonymous
  }
}
    `;

/**
 * __useGetRegisteredUserReportQuery__
 *
 * To run a query within a React component, call `useGetRegisteredUserReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredUserReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredUserReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRegisteredUserReportQuery(baseOptions?: Apollo.QueryHookOptions<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>(GetRegisteredUserReportDocument, options);
      }
export function useGetRegisteredUserReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>(GetRegisteredUserReportDocument, options);
        }
export function useGetRegisteredUserReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>(GetRegisteredUserReportDocument, options);
        }
export type GetRegisteredUserReportQueryHookResult = ReturnType<typeof useGetRegisteredUserReportQuery>;
export type GetRegisteredUserReportLazyQueryHookResult = ReturnType<typeof useGetRegisteredUserReportLazyQuery>;
export type GetRegisteredUserReportSuspenseQueryHookResult = ReturnType<typeof useGetRegisteredUserReportSuspenseQuery>;
export type GetRegisteredUserReportQueryResult = Apollo.QueryResult<GetRegisteredUserReportQuery, GetRegisteredUserReportQueryVariables>;
export const VisitPageByUsersDocument = gql`
    query visitPageByUsers($where: VisitPageByUsersPayload) {
  visitPageByUsers(where: $where) {
    total_users_registered
    total_user_anonymous
  }
}
    `;

/**
 * __useVisitPageByUsersQuery__
 *
 * To run a query within a React component, call `useVisitPageByUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPageByUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPageByUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVisitPageByUsersQuery(baseOptions?: Apollo.QueryHookOptions<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>(VisitPageByUsersDocument, options);
      }
export function useVisitPageByUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>(VisitPageByUsersDocument, options);
        }
export function useVisitPageByUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>(VisitPageByUsersDocument, options);
        }
export type VisitPageByUsersQueryHookResult = ReturnType<typeof useVisitPageByUsersQuery>;
export type VisitPageByUsersLazyQueryHookResult = ReturnType<typeof useVisitPageByUsersLazyQuery>;
export type VisitPageByUsersSuspenseQueryHookResult = ReturnType<typeof useVisitPageByUsersSuspenseQuery>;
export type VisitPageByUsersQueryResult = Apollo.QueryResult<VisitPageByUsersQuery, VisitPageByUsersQueryVariables>;
export const GetUrlVisitReportDocument = gql`
    query GetUrlVisitReport($where: UrlVisitPayload) {
  getUrlVisitReport(where: $where) {
    data {
      url
      count
    }
    total
  }
}
    `;

/**
 * __useGetUrlVisitReportQuery__
 *
 * To run a query within a React component, call `useGetUrlVisitReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlVisitReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlVisitReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUrlVisitReportQuery(baseOptions?: Apollo.QueryHookOptions<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>(GetUrlVisitReportDocument, options);
      }
export function useGetUrlVisitReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>(GetUrlVisitReportDocument, options);
        }
export function useGetUrlVisitReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>(GetUrlVisitReportDocument, options);
        }
export type GetUrlVisitReportQueryHookResult = ReturnType<typeof useGetUrlVisitReportQuery>;
export type GetUrlVisitReportLazyQueryHookResult = ReturnType<typeof useGetUrlVisitReportLazyQuery>;
export type GetUrlVisitReportSuspenseQueryHookResult = ReturnType<typeof useGetUrlVisitReportSuspenseQuery>;
export type GetUrlVisitReportQueryResult = Apollo.QueryResult<GetUrlVisitReportQuery, GetUrlVisitReportQueryVariables>;
export const SectionReportDocument = gql`
    query SectionReport($where: SectionReportPayload) {
  sectionReport(where: $where) {
    name
    count
  }
}
    `;

/**
 * __useSectionReportQuery__
 *
 * To run a query within a React component, call `useSectionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSectionReportQuery(baseOptions?: Apollo.QueryHookOptions<SectionReportQuery, SectionReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectionReportQuery, SectionReportQueryVariables>(SectionReportDocument, options);
      }
export function useSectionReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectionReportQuery, SectionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectionReportQuery, SectionReportQueryVariables>(SectionReportDocument, options);
        }
export function useSectionReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SectionReportQuery, SectionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SectionReportQuery, SectionReportQueryVariables>(SectionReportDocument, options);
        }
export type SectionReportQueryHookResult = ReturnType<typeof useSectionReportQuery>;
export type SectionReportLazyQueryHookResult = ReturnType<typeof useSectionReportLazyQuery>;
export type SectionReportSuspenseQueryHookResult = ReturnType<typeof useSectionReportSuspenseQuery>;
export type SectionReportQueryResult = Apollo.QueryResult<SectionReportQuery, SectionReportQueryVariables>;
export const GetSwgTapDocument = gql`
    query GetSwgTap($where: SwgTapPayload) {
  getSwgTap(where: $where) {
    total_swg_in_home
    total_swg_in_other_section
  }
}
    `;

/**
 * __useGetSwgTapQuery__
 *
 * To run a query within a React component, call `useGetSwgTapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwgTapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwgTapQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSwgTapQuery(baseOptions?: Apollo.QueryHookOptions<GetSwgTapQuery, GetSwgTapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSwgTapQuery, GetSwgTapQueryVariables>(GetSwgTapDocument, options);
      }
export function useGetSwgTapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSwgTapQuery, GetSwgTapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSwgTapQuery, GetSwgTapQueryVariables>(GetSwgTapDocument, options);
        }
export function useGetSwgTapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSwgTapQuery, GetSwgTapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSwgTapQuery, GetSwgTapQueryVariables>(GetSwgTapDocument, options);
        }
export type GetSwgTapQueryHookResult = ReturnType<typeof useGetSwgTapQuery>;
export type GetSwgTapLazyQueryHookResult = ReturnType<typeof useGetSwgTapLazyQuery>;
export type GetSwgTapSuspenseQueryHookResult = ReturnType<typeof useGetSwgTapSuspenseQuery>;
export type GetSwgTapQueryResult = Apollo.QueryResult<GetSwgTapQuery, GetSwgTapQueryVariables>;
export const SwgTapBySectionReportDocument = gql`
    query SwgTapBySectionReport($where: SwgTapBySectionReportPayload) {
  swgTapBySectionReport(where: $where) {
    name
    count
  }
}
    `;

/**
 * __useSwgTapBySectionReportQuery__
 *
 * To run a query within a React component, call `useSwgTapBySectionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwgTapBySectionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwgTapBySectionReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSwgTapBySectionReportQuery(baseOptions?: Apollo.QueryHookOptions<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>(SwgTapBySectionReportDocument, options);
      }
export function useSwgTapBySectionReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>(SwgTapBySectionReportDocument, options);
        }
export function useSwgTapBySectionReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>(SwgTapBySectionReportDocument, options);
        }
export type SwgTapBySectionReportQueryHookResult = ReturnType<typeof useSwgTapBySectionReportQuery>;
export type SwgTapBySectionReportLazyQueryHookResult = ReturnType<typeof useSwgTapBySectionReportLazyQuery>;
export type SwgTapBySectionReportSuspenseQueryHookResult = ReturnType<typeof useSwgTapBySectionReportSuspenseQuery>;
export type SwgTapBySectionReportQueryResult = Apollo.QueryResult<SwgTapBySectionReportQuery, SwgTapBySectionReportQueryVariables>;
export const SwgTapByMonthReportDocument = gql`
    query SwgTapByMonthReport($where: SwgTapByMonthPayload) {
  swgTapByMonthReport(where: $where) {
    date
    count
  }
}
    `;

/**
 * __useSwgTapByMonthReportQuery__
 *
 * To run a query within a React component, call `useSwgTapByMonthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwgTapByMonthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwgTapByMonthReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSwgTapByMonthReportQuery(baseOptions?: Apollo.QueryHookOptions<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>(SwgTapByMonthReportDocument, options);
      }
export function useSwgTapByMonthReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>(SwgTapByMonthReportDocument, options);
        }
export function useSwgTapByMonthReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>(SwgTapByMonthReportDocument, options);
        }
export type SwgTapByMonthReportQueryHookResult = ReturnType<typeof useSwgTapByMonthReportQuery>;
export type SwgTapByMonthReportLazyQueryHookResult = ReturnType<typeof useSwgTapByMonthReportLazyQuery>;
export type SwgTapByMonthReportSuspenseQueryHookResult = ReturnType<typeof useSwgTapByMonthReportSuspenseQuery>;
export type SwgTapByMonthReportQueryResult = Apollo.QueryResult<SwgTapByMonthReportQuery, SwgTapByMonthReportQueryVariables>;
export const SwgTapByUrlReportDocument = gql`
    query SwgTapByUrlReport($where: SwgTapByUrlPayload, $page: Int, $pageSize: Int) {
  swgTapByUrlReport(where: $where, page: $page, pageSize: $pageSize) {
    data {
      url
      count
    }
    total
  }
}
    `;

/**
 * __useSwgTapByUrlReportQuery__
 *
 * To run a query within a React component, call `useSwgTapByUrlReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwgTapByUrlReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwgTapByUrlReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useSwgTapByUrlReportQuery(baseOptions?: Apollo.QueryHookOptions<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>(SwgTapByUrlReportDocument, options);
      }
export function useSwgTapByUrlReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>(SwgTapByUrlReportDocument, options);
        }
export function useSwgTapByUrlReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>(SwgTapByUrlReportDocument, options);
        }
export type SwgTapByUrlReportQueryHookResult = ReturnType<typeof useSwgTapByUrlReportQuery>;
export type SwgTapByUrlReportLazyQueryHookResult = ReturnType<typeof useSwgTapByUrlReportLazyQuery>;
export type SwgTapByUrlReportSuspenseQueryHookResult = ReturnType<typeof useSwgTapByUrlReportSuspenseQuery>;
export type SwgTapByUrlReportQueryResult = Apollo.QueryResult<SwgTapByUrlReportQuery, SwgTapByUrlReportQueryVariables>;
export const SwgTapByUrlReportMetricDocument = gql`
    query SwgTapByUrlReportMetric($where: SwgTapByUrlMetricPayload) {
  swgTapByUrlReportMetric(where: $where) {
    data {
      url
      count
    }
  }
}
    `;

/**
 * __useSwgTapByUrlReportMetricQuery__
 *
 * To run a query within a React component, call `useSwgTapByUrlReportMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useSwgTapByUrlReportMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwgTapByUrlReportMetricQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSwgTapByUrlReportMetricQuery(baseOptions?: Apollo.QueryHookOptions<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>(SwgTapByUrlReportMetricDocument, options);
      }
export function useSwgTapByUrlReportMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>(SwgTapByUrlReportMetricDocument, options);
        }
export function useSwgTapByUrlReportMetricSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>(SwgTapByUrlReportMetricDocument, options);
        }
export type SwgTapByUrlReportMetricQueryHookResult = ReturnType<typeof useSwgTapByUrlReportMetricQuery>;
export type SwgTapByUrlReportMetricLazyQueryHookResult = ReturnType<typeof useSwgTapByUrlReportMetricLazyQuery>;
export type SwgTapByUrlReportMetricSuspenseQueryHookResult = ReturnType<typeof useSwgTapByUrlReportMetricSuspenseQuery>;
export type SwgTapByUrlReportMetricQueryResult = Apollo.QueryResult<SwgTapByUrlReportMetricQuery, SwgTapByUrlReportMetricQueryVariables>;
export const GetClickedReportUserDocument = gql`
    query getClickedReportUser($where: ClickReportUserPayload) {
  getClickedReportUser(where: $where) {
    name
    count
    __typename
  }
}
    `;

/**
 * __useGetClickedReportUserQuery__
 *
 * To run a query within a React component, call `useGetClickedReportUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClickedReportUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClickedReportUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClickedReportUserQuery(baseOptions?: Apollo.QueryHookOptions<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>(GetClickedReportUserDocument, options);
      }
export function useGetClickedReportUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>(GetClickedReportUserDocument, options);
        }
export function useGetClickedReportUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>(GetClickedReportUserDocument, options);
        }
export type GetClickedReportUserQueryHookResult = ReturnType<typeof useGetClickedReportUserQuery>;
export type GetClickedReportUserLazyQueryHookResult = ReturnType<typeof useGetClickedReportUserLazyQuery>;
export type GetClickedReportUserSuspenseQueryHookResult = ReturnType<typeof useGetClickedReportUserSuspenseQuery>;
export type GetClickedReportUserQueryResult = Apollo.QueryResult<GetClickedReportUserQuery, GetClickedReportUserQueryVariables>;
export const UserSessionDocument = gql`
    query UserSession($where: UserSessionWhere) {
  userSession(where: $where) {
    count
    date
  }
}
    `;

/**
 * __useUserSessionQuery__
 *
 * To run a query within a React component, call `useUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSessionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserSessionQuery(baseOptions?: Apollo.QueryHookOptions<UserSessionQuery, UserSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSessionQuery, UserSessionQueryVariables>(UserSessionDocument, options);
      }
export function useUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSessionQuery, UserSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSessionQuery, UserSessionQueryVariables>(UserSessionDocument, options);
        }
export function useUserSessionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserSessionQuery, UserSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserSessionQuery, UserSessionQueryVariables>(UserSessionDocument, options);
        }
export type UserSessionQueryHookResult = ReturnType<typeof useUserSessionQuery>;
export type UserSessionLazyQueryHookResult = ReturnType<typeof useUserSessionLazyQuery>;
export type UserSessionSuspenseQueryHookResult = ReturnType<typeof useUserSessionSuspenseQuery>;
export type UserSessionQueryResult = Apollo.QueryResult<UserSessionQuery, UserSessionQueryVariables>;
export const WinnerNotesDocument = gql`
    query WinnerNotes($where: WinnerNotesWhere, $page: Int, $pageSize: Int, $order: WinnerNoteOrder) {
  winnerNotes(where: $where, page: $page, pageSize: $pageSize, order: $order) {
    items {
      count
      url
    }
    total
  }
}
    `;

/**
 * __useWinnerNotesQuery__
 *
 * To run a query within a React component, call `useWinnerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinnerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinnerNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWinnerNotesQuery(baseOptions?: Apollo.QueryHookOptions<WinnerNotesQuery, WinnerNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinnerNotesQuery, WinnerNotesQueryVariables>(WinnerNotesDocument, options);
      }
export function useWinnerNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinnerNotesQuery, WinnerNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinnerNotesQuery, WinnerNotesQueryVariables>(WinnerNotesDocument, options);
        }
export function useWinnerNotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WinnerNotesQuery, WinnerNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WinnerNotesQuery, WinnerNotesQueryVariables>(WinnerNotesDocument, options);
        }
export type WinnerNotesQueryHookResult = ReturnType<typeof useWinnerNotesQuery>;
export type WinnerNotesLazyQueryHookResult = ReturnType<typeof useWinnerNotesLazyQuery>;
export type WinnerNotesSuspenseQueryHookResult = ReturnType<typeof useWinnerNotesSuspenseQuery>;
export type WinnerNotesQueryResult = Apollo.QueryResult<WinnerNotesQuery, WinnerNotesQueryVariables>;
export const PlansDocument = gql`
    query Plans($where: PlanWhereInput) {
  plans(where: $where) {
    count
    items {
      id
      title
      description
      status
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export function usePlansSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansSuspenseQueryHookResult = ReturnType<typeof usePlansSuspenseQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    createdAt
    description
    id
    title
    updatedAt
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    id
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const TenantsDocument = gql`
    query Tenants {
  tenants {
    count
    items {
      allowedUrls
      createdAt
      id
      logo
      name
      identityColor
      status
      plan {
        createdAt
        description
        id
        title
        updatedAt
      }
      timezone
      updatedAt
      webhook
    }
  }
}
    `;

/**
 * __useTenantsQuery__
 *
 * To run a query within a React component, call `useTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsQuery(baseOptions?: Apollo.QueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
      }
export function useTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
        }
export function useTenantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
        }
export type TenantsQueryHookResult = ReturnType<typeof useTenantsQuery>;
export type TenantsLazyQueryHookResult = ReturnType<typeof useTenantsLazyQuery>;
export type TenantsSuspenseQueryHookResult = ReturnType<typeof useTenantsSuspenseQuery>;
export type TenantsQueryResult = Apollo.QueryResult<TenantsQuery, TenantsQueryVariables>;
export const CreateTenantDocument = gql`
    mutation CreateTenant($input: CreateTenantInput!) {
  createTenant(input: $input) {
    allowedUrls
    createdAt
    id
    logo
    name
    identityColor
    status
    plan {
      createdAt
      description
      id
      title
      updatedAt
    }
    timezone
    updatedAt
    webhook
  }
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const TenantDocument = gql`
    query Tenant($tenantId: ID!) {
  tenant(id: $tenantId) {
    allowedUrls
    createdAt
    id
    logo
    name
    identityColor
    status
    plan {
      createdAt
      description
      id
      title
      updatedAt
    }
    timezone
    updatedAt
    webhook
  }
}
    `;

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantQuery(baseOptions: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
      }
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
        }
export function useTenantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantQuery, TenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
        }
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantSuspenseQueryHookResult = ReturnType<typeof useTenantSuspenseQuery>;
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($input: UpdateTenantInput!) {
  updateTenant(input: $input) {
    allowedUrls
    createdAt
    id
    logo
    name
    identityColor
    status
    plan {
      createdAt
      description
      id
      title
      updatedAt
    }
    timezone
    updatedAt
    webhook
  }
}
    `;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const TenantUsersDocument = gql`
    query TenantUsers($where: TenantUserWhereInput, $page: Int, $pageSize: Int) {
  tenantUsers(where: $where, page: $page, pageSize: $pageSize) {
    count
    items {
      id
      createdAt
      role
      status
      tenant {
        name
        id
        identityColor
        logo
        status
      }
      user {
        name
        avatar
        email
        id
        role
        last_login
      }
    }
  }
}
    `;

/**
 * __useTenantUsersQuery__
 *
 * To run a query within a React component, call `useTenantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTenantUsersQuery(baseOptions?: Apollo.QueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
      }
export function useTenantUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
        }
export function useTenantUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantUsersQuery, TenantUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantUsersQuery, TenantUsersQueryVariables>(TenantUsersDocument, options);
        }
export type TenantUsersQueryHookResult = ReturnType<typeof useTenantUsersQuery>;
export type TenantUsersLazyQueryHookResult = ReturnType<typeof useTenantUsersLazyQuery>;
export type TenantUsersSuspenseQueryHookResult = ReturnType<typeof useTenantUsersSuspenseQuery>;
export type TenantUsersQueryResult = Apollo.QueryResult<TenantUsersQuery, TenantUsersQueryVariables>;
export const TenantUserInvitationsDocument = gql`
    query TenantUserInvitations($where: TenantUserInvitationWhereInput, $page: Int, $pageSize: Int) {
  tenantUserInvitations(where: $where, page: $page, pageSize: $pageSize) {
    count
    items {
      id
      role
      status
      email
      createdAt
      updatedAt
      tenant {
        name
      }
    }
  }
}
    `;

/**
 * __useTenantUserInvitationsQuery__
 *
 * To run a query within a React component, call `useTenantUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantUserInvitationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTenantUserInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>(TenantUserInvitationsDocument, options);
      }
export function useTenantUserInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>(TenantUserInvitationsDocument, options);
        }
export function useTenantUserInvitationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>(TenantUserInvitationsDocument, options);
        }
export type TenantUserInvitationsQueryHookResult = ReturnType<typeof useTenantUserInvitationsQuery>;
export type TenantUserInvitationsLazyQueryHookResult = ReturnType<typeof useTenantUserInvitationsLazyQuery>;
export type TenantUserInvitationsSuspenseQueryHookResult = ReturnType<typeof useTenantUserInvitationsSuspenseQuery>;
export type TenantUserInvitationsQueryResult = Apollo.QueryResult<TenantUserInvitationsQuery, TenantUserInvitationsQueryVariables>;
export const CreateTenantUserInvitationDocument = gql`
    mutation CreateTenantUserInvitation($input: CreateTenantUserInvitationInput) {
  createTenantUserInvitation(input: $input) {
    id
  }
}
    `;
export type CreateTenantUserInvitationMutationFn = Apollo.MutationFunction<CreateTenantUserInvitationMutation, CreateTenantUserInvitationMutationVariables>;

/**
 * __useCreateTenantUserInvitationMutation__
 *
 * To run a mutation, you first call `useCreateTenantUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantUserInvitationMutation, { data, loading, error }] = useCreateTenantUserInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantUserInvitationMutation, CreateTenantUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantUserInvitationMutation, CreateTenantUserInvitationMutationVariables>(CreateTenantUserInvitationDocument, options);
      }
export type CreateTenantUserInvitationMutationHookResult = ReturnType<typeof useCreateTenantUserInvitationMutation>;
export type CreateTenantUserInvitationMutationResult = Apollo.MutationResult<CreateTenantUserInvitationMutation>;
export type CreateTenantUserInvitationMutationOptions = Apollo.BaseMutationOptions<CreateTenantUserInvitationMutation, CreateTenantUserInvitationMutationVariables>;
export const UpdateTenantUserInvitationDocument = gql`
    mutation UpdateTenantUserInvitation($input: UpdateTenantUserInvitationInput) {
  updateTenantUserInvitation(input: $input) {
    id
    status
  }
}
    `;
export type UpdateTenantUserInvitationMutationFn = Apollo.MutationFunction<UpdateTenantUserInvitationMutation, UpdateTenantUserInvitationMutationVariables>;

/**
 * __useUpdateTenantUserInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateTenantUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantUserInvitationMutation, { data, loading, error }] = useUpdateTenantUserInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantUserInvitationMutation, UpdateTenantUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantUserInvitationMutation, UpdateTenantUserInvitationMutationVariables>(UpdateTenantUserInvitationDocument, options);
      }
export type UpdateTenantUserInvitationMutationHookResult = ReturnType<typeof useUpdateTenantUserInvitationMutation>;
export type UpdateTenantUserInvitationMutationResult = Apollo.MutationResult<UpdateTenantUserInvitationMutation>;
export type UpdateTenantUserInvitationMutationOptions = Apollo.BaseMutationOptions<UpdateTenantUserInvitationMutation, UpdateTenantUserInvitationMutationVariables>;
export const RemoveTenantUserInvitationDocument = gql`
    mutation RemoveTenantUserInvitation($input: RemoveTenantUserInvitationInput) {
  removeTenantUserInvitation(input: $input) {
    success
  }
}
    `;
export type RemoveTenantUserInvitationMutationFn = Apollo.MutationFunction<RemoveTenantUserInvitationMutation, RemoveTenantUserInvitationMutationVariables>;

/**
 * __useRemoveTenantUserInvitationMutation__
 *
 * To run a mutation, you first call `useRemoveTenantUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTenantUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTenantUserInvitationMutation, { data, loading, error }] = useRemoveTenantUserInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTenantUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTenantUserInvitationMutation, RemoveTenantUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTenantUserInvitationMutation, RemoveTenantUserInvitationMutationVariables>(RemoveTenantUserInvitationDocument, options);
      }
export type RemoveTenantUserInvitationMutationHookResult = ReturnType<typeof useRemoveTenantUserInvitationMutation>;
export type RemoveTenantUserInvitationMutationResult = Apollo.MutationResult<RemoveTenantUserInvitationMutation>;
export type RemoveTenantUserInvitationMutationOptions = Apollo.BaseMutationOptions<RemoveTenantUserInvitationMutation, RemoveTenantUserInvitationMutationVariables>;
export const ResendTenantUserInvitationDocument = gql`
    mutation ResendTenantUserInvitation($input: ResendTenantUserInvitationInput) {
  resendTenantUserInvitation(input: $input) {
    id
    role
    email
    status
    createdAt
    updatedAt
  }
}
    `;
export type ResendTenantUserInvitationMutationFn = Apollo.MutationFunction<ResendTenantUserInvitationMutation, ResendTenantUserInvitationMutationVariables>;

/**
 * __useResendTenantUserInvitationMutation__
 *
 * To run a mutation, you first call `useResendTenantUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTenantUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTenantUserInvitationMutation, { data, loading, error }] = useResendTenantUserInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendTenantUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendTenantUserInvitationMutation, ResendTenantUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendTenantUserInvitationMutation, ResendTenantUserInvitationMutationVariables>(ResendTenantUserInvitationDocument, options);
      }
export type ResendTenantUserInvitationMutationHookResult = ReturnType<typeof useResendTenantUserInvitationMutation>;
export type ResendTenantUserInvitationMutationResult = Apollo.MutationResult<ResendTenantUserInvitationMutation>;
export type ResendTenantUserInvitationMutationOptions = Apollo.BaseMutationOptions<ResendTenantUserInvitationMutation, ResendTenantUserInvitationMutationVariables>;
export const UpdateTenantUserDocument = gql`
    mutation UpdateTenantUser($input: UpdateTenantUserInput) {
  updateTenantUser(input: $input) {
    id
  }
}
    `;
export type UpdateTenantUserMutationFn = Apollo.MutationFunction<UpdateTenantUserMutation, UpdateTenantUserMutationVariables>;

/**
 * __useUpdateTenantUserMutation__
 *
 * To run a mutation, you first call `useUpdateTenantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantUserMutation, { data, loading, error }] = useUpdateTenantUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantUserMutation, UpdateTenantUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantUserMutation, UpdateTenantUserMutationVariables>(UpdateTenantUserDocument, options);
      }
export type UpdateTenantUserMutationHookResult = ReturnType<typeof useUpdateTenantUserMutation>;
export type UpdateTenantUserMutationResult = Apollo.MutationResult<UpdateTenantUserMutation>;
export type UpdateTenantUserMutationOptions = Apollo.BaseMutationOptions<UpdateTenantUserMutation, UpdateTenantUserMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    tenantUsers {
      id
      role
      tenant {
        id
        name
        timezone
        webhook
        logo
        identityColor
        plan {
          createdAt
          description
          id
          title
          updatedAt
        }
      }
    }
    id
    name
    email
    last_login
    updated_at
    created_at
    avatar
    role
    timezone
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserInvitationDocument = gql`
    mutation CreateUserInvitation($input: CreateUserInvitationInput) {
  createUserInvitation(input: $input) {
    id
  }
}
    `;
export type CreateUserInvitationMutationFn = Apollo.MutationFunction<CreateUserInvitationMutation, CreateUserInvitationMutationVariables>;

/**
 * __useCreateUserInvitationMutation__
 *
 * To run a mutation, you first call `useCreateUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserInvitationMutation, { data, loading, error }] = useCreateUserInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserInvitationMutation, CreateUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserInvitationMutation, CreateUserInvitationMutationVariables>(CreateUserInvitationDocument, options);
      }
export type CreateUserInvitationMutationHookResult = ReturnType<typeof useCreateUserInvitationMutation>;
export type CreateUserInvitationMutationResult = Apollo.MutationResult<CreateUserInvitationMutation>;
export type CreateUserInvitationMutationOptions = Apollo.BaseMutationOptions<CreateUserInvitationMutation, CreateUserInvitationMutationVariables>;
export const UserInvitationsDocument = gql`
    query UserInvitations {
  userInvitations {
    items {
      id
      role
      email
      status
      createdAt
    }
    count
  }
}
    `;

/**
 * __useUserInvitationsQuery__
 *
 * To run a query within a React component, call `useUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<UserInvitationsQuery, UserInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInvitationsQuery, UserInvitationsQueryVariables>(UserInvitationsDocument, options);
      }
export function useUserInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInvitationsQuery, UserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInvitationsQuery, UserInvitationsQueryVariables>(UserInvitationsDocument, options);
        }
export function useUserInvitationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserInvitationsQuery, UserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserInvitationsQuery, UserInvitationsQueryVariables>(UserInvitationsDocument, options);
        }
export type UserInvitationsQueryHookResult = ReturnType<typeof useUserInvitationsQuery>;
export type UserInvitationsLazyQueryHookResult = ReturnType<typeof useUserInvitationsLazyQuery>;
export type UserInvitationsSuspenseQueryHookResult = ReturnType<typeof useUserInvitationsSuspenseQuery>;
export type UserInvitationsQueryResult = Apollo.QueryResult<UserInvitationsQuery, UserInvitationsQueryVariables>;
export const UserEventsDocument = gql`
    query UserEvents($userId: Int) {
  userEvents(user_id: $userId) {
    count
    events {
      id
      created_at
      name
      user_id
      event_meta {
        id
        meta_key
        meta_value
      }
    }
  }
}
    `;

/**
 * __useUserEventsQuery__
 *
 * To run a query within a React component, call `useUserEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserEventsQuery(baseOptions?: Apollo.QueryHookOptions<UserEventsQuery, UserEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEventsQuery, UserEventsQueryVariables>(UserEventsDocument, options);
      }
export function useUserEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEventsQuery, UserEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEventsQuery, UserEventsQueryVariables>(UserEventsDocument, options);
        }
export function useUserEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserEventsQuery, UserEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserEventsQuery, UserEventsQueryVariables>(UserEventsDocument, options);
        }
export type UserEventsQueryHookResult = ReturnType<typeof useUserEventsQuery>;
export type UserEventsLazyQueryHookResult = ReturnType<typeof useUserEventsLazyQuery>;
export type UserEventsSuspenseQueryHookResult = ReturnType<typeof useUserEventsSuspenseQuery>;
export type UserEventsQueryResult = Apollo.QueryResult<UserEventsQuery, UserEventsQueryVariables>;