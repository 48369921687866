import React, { useEffect } from 'react';
import { CardHeader, Card, Box, CardContent } from '@mui/material';
import { useStylesCustom } from '../DashboardCardItem';
import { useUserSessionLazyQuery } from '../../../../shared/types/generated';
import moment from 'moment';
import { Loading } from '../../../../shared/components/Loading';
import { CustomSizes } from '../../../../shared/components/loading-types';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import es from 'apexcharts/dist/locales/es.json';
import { CARD_HEIGHT_DASHBOARD } from '../../../../shared/constants';
import { useAuth } from '../../../auth/auth-hook';
import { getSessionCategories } from '../../dashboard-utils';

export const DashboardAnonymousUserSession: React.FC = () => {
  const classes = useStylesCustom();
  const { tenant } = useAuth();
  const [callAction, { loading, data }] = useUserSessionLazyQuery();

  useEffect(() => {
    callAction({
      variables: {
        where: {
          from: moment().subtract(1, 'hour').toISOString(),
          to: moment().toISOString(),
          tenantId: tenant?.id,
        },
      },
    });

  }, [callAction, tenant?.id]);


  const categories = getSessionCategories();


  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Usuarios anonimos activos',
        type: 'column',
        data: categories.map((cat) => {
          const session = data?.userSession?.find((ses) => moment(cat).tz(tenant?.timezone || 'America/Mexico_City').format('YYYY-MM-DD HH:mm') === ses?.date);

          return session?.count || 0;

        }),
      },
    ],
    options: {
      colors: [tenant?.identityColor, 'rgba(50,50,50,.85)'],
      chart: {
        id: 'user-anonymous-chart',
        height: 300,
        type: 'bar',
        defaultLocale: 'es',
        locales: [es],
      },
      stroke: {
        width: [0, 3],
      },
      markers: {
        size: 0,
      },
      dataLabels: {
        enabled: false,
        formatter: function(val: string) {
          return val.toLocaleString();
        },
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        categories: categories,
        position: 'bottom',
        type: 'datetime',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 3,
        labels: {
          formatter(value: string) {
            return moment(value).fromNow();
          },
          offsetX: 10,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          formatter: function(val: any) {
            return val.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Card
      elevation={2}
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      <CardHeader
        title='Usuarios anonimos activos la última hora'
        classes={{
          title: classes.title,
        }}
        className={classes.header}
      />
      <CardContent
        className={classes.content}
        style={{ height: CARD_HEIGHT_DASHBOARD }}
      >
        {loading ? (
          <Box
            fontSize={32}
            display='flex'
            justifyContent='center'
            alignItems='center'
            position={'absolute'}
            height={'94%'}
          >
            <Loading customSize={CustomSizes.SMALL} />
          </Box>
        ) : (
          <Chart
            options={DATA.options}
            series={DATA.series}
            type='bar'
            height={'100%'}
          />
        )}
      </CardContent>


    </Card>
  );
};
