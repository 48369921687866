import { createAction } from '@cobuildlab/react-simple-state';
import {
  createSharedCouponEvent,
  createSharedCouponEventError,
  editCouponEvent,
  editCouponEventError,
  enableOrDisableStripeCouponEvent,
  enableOrDisableStripeCouponEventError,
} from './coupon-events';
import { Tenant } from '../../../shared/types/generated';
import axios from 'axios';
import { TenantSubsPlanStripeCouponType } from '../tenant-subs-plan-types';
import moment from 'moment';

export const createSharedCoupon = createAction(
  createSharedCouponEvent,
  createSharedCouponEventError,
  async (data: {
    tenant: Tenant;
    couponId: number;
    emails: string[];
    isReferral: boolean;
  }) => {
    const { tenant, couponId, emails, isReferral } = data;

    const urlShared = `${tenant.webhook}/api/coupon/${couponId}/shared-coupon`;
    const urlReferral = `${tenant.webhook}/api/coupon/${couponId}/assign-referral-coupon`;
    const url = isReferral ? urlReferral : urlShared;

    try {
      await axios.post(url, { emails }, { timeout: 60000 });
    } catch (error: any) {
      console.log('error', error);
      const msg =
        error?.response?.data?.message ?? 'Error al compartir el cupón';
      throw new Error(msg);
    }
  },
);

export const enableOrDisableStripeCoupon = createAction(
  enableOrDisableStripeCouponEvent,
  enableOrDisableStripeCouponEventError,
  async (data: { tenant: Tenant; couponId: number }) => {
    const { tenant, couponId } = data;

    const url = `${tenant.webhook}/api/coupon/${couponId}/toggle`;

    try {
      const response = await axios.post<TenantSubsPlanStripeCouponType>(url);

      return response.data;
    } catch (error: any) {
      console.log('error', error);
      const msg =
        error?.response?.data?.message ?? 'Error al actualizar el cupón';
      throw new Error(msg);
    }
  },
);

export const editCoupon = createAction(
  editCouponEvent,
  editCouponEventError,
  async (data: {
    tenant: Tenant;
    id: number;
    plans: string[];
    name: string;
    discountType: string;
    maxRedemptions?: number | null;
    expirationDate?: string | null;
    isPublic?: boolean;
    isForSharing?: boolean;
  }) => {
    const { tenant, id, plans } = data;
    const url = `${tenant.webhook}/api/update-coupon/${id}`;

    try {
      const response = await axios.post<TenantSubsPlanStripeCouponType>(url, {
        couponName: data.name,
        discountType: data.discountType,
        maxRedemptions: data.maxRedemptions,
        expirationDate: data?.expirationDate
          ? moment(data.expirationDate).toISOString()
          : null,
        isPublic: data.isPublic,
        isForSharing: data.isForSharing,
        plans,
      });
      return response.data;
    } catch (error: any) {
      const msg = error?.response?.data?.message ?? 'Error al actualizar el cupón';
      throw new Error(msg);
    }
  },
);
